import React from "react";

import * as s from './styles'

export default function Partners(){
    return(
        <s.partnersWrapper>
            
        </s.partnersWrapper>
    )
}